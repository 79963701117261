import React, { useEffect } from "react"
import Meta from "../utilities/seo"

const Ele = () => {
  useEffect(() => {
    window.location.href =
      "https://xeno-orbit-cdn.xn-io.com/cdn/media/documents/XENO_Direct_Debit_Agreement.pdf"
  }, [])
  return (
    <>
      <Meta title="Direct Debit Agreement" />
    </>
  )
}

export default Ele
